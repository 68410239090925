import React from "react"
import { Helmet } from "react-helmet"

function Index() {
  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>buku Happy Love Guide</title>
        </Helmet>
      </div>
      <div className="container-fluid __bg-primary-new-hlg" style={{height:"100vh"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="wrappe-headline-top-text"
                style={{
                  fontSize: "20px",
                  marginBottom: "50px",
                  marginTop: "0px",
                }}
              >
                <p className="text-white">
                  <b>TERIMA KASIH SUDAH MEMBELI BUKU HAPPY LOVE GUIDE</b>
                </p>
                <h3 className="text-white">
                  <span style={{ background: "#594b7b" }}>
                    Info nomor resi akan dikirimkan via email <br/> Segera cek inbox/promotion/spam di email kamu
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="d-flex justify-content-center">
                <img
                    className="img-fluid img-testimoni-book"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/section-4.jpg"
                    alt="testimoni4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="text-center text-footer-faq text-white">
                © {new Date().getFullYear()}, Built with Lovecoach.id
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Index
